import type { Map } from "maplibre-gl";

const maps: Record<string, Map> = {};

export const getMap = (name: string): Map | undefined => {
  return maps[name];
};

export const setMap = (name: string, map: Map): void => {
  maps[name] = map;
};
