/* eslint-disable max-lines-per-function */
import { ChevronsLeft } from "@styled-icons/feather/ChevronsLeft";
import { ChevronsRight } from "@styled-icons/feather/ChevronsRight";
import useKeybind, { Keybinding } from "@utilityjs/use-keybind/useKeybind";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ActionCreators } from "redux-undo";
import { Grid } from "theme-ui";
import { RootState } from "../../lib/configureStore";
import IconButton from "./ToolButton";

// undo and redo events
const undoHandler = (dispatch: Dispatch) => (): void => {
	dispatch(ActionCreators.undo());
};

const redoHandler = (dispatch: Dispatch) => (): void => {
	dispatch(ActionCreators.redo());
};

const UndoRedo: React.FunctionComponent = () => {
	const [pastLength, futureLength] = useSelector(
		(state: RootState): readonly number[] => [
			state.objects.past.length,
			state.objects.future.length,
		]
	);
	const dispatch = useDispatch();

	// useHotkeys("ctrl+z", undoHandler(dispatch), { keyup: true }, [dispatch]);
	// useHotkeys("ctrl+y", redoHandler(dispatch), { keyup: true }, [dispatch]);

	const keybinds: Keybinding[] = [
		{ keys: ["Control", "z"], callback: undoHandler(dispatch) },
		{ keys: ["Control", "y"], callback: redoHandler(dispatch) },
	];

	useKeybind(window, keybinds);

	return (
		<Grid
			sx={{ gridAutoFlow: "row", gridArea: "undo", gridColumn: "span 1 / 3" }}
		>
			{pastLength > 0 ? (
				<IconButton
					onClick={(e) => {
						undoHandler(dispatch)();

						setTimeout(() => {
							(e.target as HTMLButtonElement).blur();
						}, 1000);
					}}
					label="Undo"
					tippyProps={{ placement: "left" }}
					icon={ChevronsLeft}
				/>
			) : null}
			{futureLength > 0 ? (
				<IconButton
					onClick={(e) => {
						redoHandler(dispatch)();

						setTimeout(() => {
							(e.target as HTMLButtonElement).blur();
						}, 1000);
					}}
					label="Redo"
					tippyProps={{ placement: "bottom" }}
					icon={ChevronsRight}
				/>
			) : null}
		</Grid>
	);
};

export default UndoRedo;
