/* eslint-disable max-lines */
import styled from "@emotion/styled";
import type { TippyProps } from "@tippyjs/react";
import Mousetrap, { MousetrapInstance } from "mousetrap";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useStore } from "react-redux";
import type { Store } from "redux";
import * as panActions from "../../actions/pan";
import { updateViewport, Viewport } from "../../actions/viewport";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import { bindKeyboardPan } from "../../lib/controls/keyboardPan";
import { bindMousePan, SelectableCanvas } from "../../lib/controls/mousePan";
import log from "../../lib/log";
import PanIcon from "../icons/grab.svg";
import ToPanIcon from "../icons/to-grab.svg";
import ToolButton from "./ToolButton";

type Properties = {
	readonly moving?: boolean;
};

const PanIconButton = styled(ToolButton)`
	svg:nth-of-type(1) {
		stroke: ${(properties: Properties): string =>
			properties.moving ? "red" : "currentColor"};
	}
`;

type PanEvent = CustomEvent<{ readonly x: number; readonly y: number }>;

/*
 * {@link https://43081j.com/2020/11/typed-events-in-typescript|Using strongly typed events in TypeScript}
 */
declare global {
	interface DocumentEventMap {
		readonly panstop: PanEvent;
	}
}

type PanChange = (e: PanEvent) => void;

/**
 * Listen to pan change events ({x: 1, y: 0})
 */
const attachPanChange =
	(store: Store<RootState>) => (canvas: SelectableCanvas<Event>) => {
		const canvasPanChange: PanChange = (e) => {
			if (canvas.viewportTransform) {
				store.dispatch(
					updateViewport(canvas.viewportTransform as unknown as Viewport)
				);
			}
		};

		document.addEventListener("panstop", canvasPanChange);

		return function cleanup() {
			document.removeEventListener("panstop", canvasPanChange);
		};
	};

/**
 * Listen to mouse panning events
 */
const attachMousePan =
	(store: Store) =>
	(canvas: SelectableCanvas<MouseEvent>): (() => void) => {
		// const previous = makeObjectsUnselectable(canvas);
		canvas.selection = false;
		canvas.selectable = false;
		canvas.defaultCursor = "grab";

		log.debug("MousePan", "attach mouse pan");

		const detachPans = [
			bindMousePan(canvas),
			attachPanChange(store)(canvas),
			// enableTouchPan(store)(canvas)
		];

		return function detach(): void {
			detachPans.map((detach) => detach());
			canvas.selection = true;
			canvas.selectable = true;
			canvas.defaultCursor = "default";
		};
	};

/**
 * Attach the keyboard pan and return the detach function
 */
const attachKeyboardPan = (
	canvas: SelectableCanvas<KeyboardEvent>
): (() => void) => {
	const detachPans = [bindKeyboardPan(canvas)];

	return function detachKeyboardPan(): void {
		detachPans.map((disablePan) => disablePan());
	};
};

/**
 * Start listening to pan events for keyboard, mouse
 */
const enablePan =
	(store: Store) =>
	(canvas: SelectableCanvas<KeyboardEvent | MouseEvent>): (() => void) => {
		const disablePans = [
			attachKeyboardPan(canvas as SelectableCanvas<KeyboardEvent>),
			attachMousePan(store)(canvas as SelectableCanvas<MouseEvent>),
		];

		return function disablePan(): void {
			disablePans.map((disable) => disable());
		};
	};

type DetachCallback = () => void;

/**
 * Toggle pan state on space down and space up
 */
const attachSpacebarToggle =
	(mt: MousetrapInstance) =>
	(setPanState: (state: boolean) => void): DetachCallback => {
		mt.bind(
			"space",
			() => {
				setPanState(true);
			},
			"keydown"
		);
		mt.bind(
			"space",
			() => {
				setPanState(false);
			},
			"keyup"
		);

		return function detach() {
			mt.unbind("space", "keyup");
			mt.unbind("space", "keydown");
		};
	};

/**
 * PanTool
 */
// eslint-disable-next-line max-lines-per-function
const PanTool: React.FunctionComponent<{
	readonly canvas?: SelectableCanvas<MouseEvent | KeyboardEvent>;
	// eslint-disable-next-line max-lines-per-function
}> = ({ canvas }) => {
	const [panState, setPanState] = useState<boolean>(false);
	const mousetrap = useMemo(() => new Mousetrap(), []);
	const store = useStore();

	// Attach spacebar keybind
	useEffect(() => {
		const detatch = attachSpacebarToggle(mousetrap)((state: boolean) => {
			setPanState(state);
			store.dispatch(state ? panActions.enablePan() : panActions.disablePan());
		});

		return function cleanup() {
			detatch();
		};
	}, [store, mousetrap, panState]);

	// NOTE:
	// Middle mouse functionality not fully functional due to no "auxdown" or "auxup"
	// events on Chrome. Chrome moved to using "auxclick" to support non-primary buttons
	// on the mouse but never made a auxdown or auxup event. This makes it not possible to
	// trigger panning on mousedown or mouseup using middle mouse button
	// eslint-disable-next-line max-lines-per-function
	useEffect(() => {
		if (!canvas) {
			return;
		}

		canvas.fireMiddleClick = true;

		const toggleOn = (e: MouseEvent): void => {
			if (e.button !== 1) {
				return;
			}

			setPanState(true);
			store.dispatch(panActions.enablePan());
			canvas.__onMouseDown(e);
		};

		const toggleOff = (e: MouseEvent): void => {
			if (e.button !== 1) {
				return;
			}

			setPanState(false);
			store.dispatch(panActions.disablePan());
			canvas.__onMouseUp(e);
		};

		document.addEventListener("mousedown", toggleOn);
		document.addEventListener("mouseup", toggleOff);

		return function cleanup() {
			document.removeEventListener("mousedown", toggleOn);
			document.removeEventListener("mouseup", toggleOff);
		};
	});

	// Attach pan events if we are panning
	useEffect(() => {
		if (!canvas) {
			return;
		}

		log.debug("PanTool", "toggling panning state", panState);

		const disablePan = panState ? enablePan(store)(canvas) : (): void => {};

		return function cleanup(): void {
			disablePan();
		};
	}, [store, canvas, panState]);

	const tippyProps: Partial<TippyProps> = {
		placement: "left",
	};

	tippyProps.visible = panState;

	return (
		<PanIconButton
			data-ispanning={panState ? "yes" : "no"}
			// moving={panState ? }
			label="Panning"
			onClick={(e) => {
				const newPanState = !panState;
				setPanState(newPanState);
				store.dispatch(
					newPanState ? panActions.enablePan() : panActions.disablePan()
				);
				if (newPanState == false) {
					(e.target as HTMLElement)?.blur();
				}
			}}
			icon={panState ? PanIcon : ToPanIcon}
			tippyProps={tippyProps}
		/>
	);
};

export default connect(({ canvas }: RootState) => ({
	canvas: getCanvas(canvas) as
		| SelectableCanvas<MouseEvent | KeyboardEvent>
		| undefined,
}))(PanTool);
