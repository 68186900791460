import { fabric } from "fabric";

import "./fabric/zoom";
import "./fabric/group";
import "./fabric/order";
import "./fabric/blob";
import "./fabric/shapes";
import "./object";
import "./controls";

export default fabric;
