import Tippy from "@tippyjs/react";
import React, { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import styled from "styled-components";
import { Box } from "theme-ui";
import { setLock, setVisiblity } from "../../actions/layers";
import { useAppSelector } from "../../lib/configureStore";
import {
  getLayerDescription,
  getLayerName,
  getLayers,
  Layer,
  LayerOption,
} from "../../shapes";
import IconButton from "../buttons/IconButton";
import EyeOffIcon from "../icons/eye-off.svg";
import EyeIcon from "../icons/eye.svg";
import LockIcon from "../icons/lock.svg";
import UnlockIcon from "../icons/unlock.svg";

const List = styled("ul")`
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 16px;
  margin: 0;

  background-color: var(--theme-ui-colors-accent-bg);
`;

const ListItem = styled("li")`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  grid-template-columns: 44px auto 44px;

  background-color: var(--theme-ui-colors-background);
`;

type LayerToggleHandler = (
  dispatch: Dispatch
) => (layer: Layer) => (newState: boolean) => MouseEventHandler;

const handleSetLock: LayerToggleHandler =
  (dispatch) => (layer) => (lock) => (e) => {
    dispatch(setLock([layer, lock]));

    (e.target as HTMLButtonElement).blur();
  };

const handleSetVisible: LayerToggleHandler =
  (dispatch) => (layer) => (visibility) => (e) => {
    dispatch(setVisiblity([layer, visibility]));

    (e.target as HTMLButtonElement).blur();
  };
const LayersList: React.FunctionComponent = () => {
  const layers = useAppSelector((state) => state.layers);
  const dispatch = useDispatch();

  return (
    <List>
      {(Object.entries(getLayers()) as [Layer, LayerOption][]).map(
        ([layer]) => {
          const layerName = getLayerName(layer);
          return (
            <ListItem key={layer}>
              <IconButton
                style={{ opacity: layers[layer]?.lock ? 1 : 0.6 }}
                onClick={handleSetLock(dispatch)(layer)(
                  layers[layer]?.lock ? false : true
                )}
                icon={layers[layer]?.lock ? LockIcon : UnlockIcon}
                label={`${
                  layers[layer]?.lock ? "Unlock" : "Lock"
                } ${layerName}`}
              />
              <Tippy
                appendTo={
                  document.querySelector("#tooltips-container") ?? undefined
                }
                content={getLayerDescription(layer).description}
              >
                <Box
                  sx={{
                    alignSelf: "center",
                    "&:hover": { cursor: "help" },
                  }}
                >
                  {layerName}
                </Box>
              </Tippy>
              <IconButton
                style={{ opacity: layers[layer]?.visible ? 1 : 0.5 }}
                onClick={handleSetVisible(dispatch)(layer)(
                  layers[layer]?.visible ? false : true
                )}
                icon={layers[layer]?.visible ? EyeIcon : EyeOffIcon}
                label={`${
                  layers[layer]?.visible ? "Fade" : "Visible"
                } ${layerName}`}
              />
            </ListItem>
          );
        }
      )}
    </List>
  );
};

export default LayersList;
