/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { Copy } from "@styled-icons/feather/Copy";
import { fabric } from "fabric";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { batch, useSelector, useStore } from "react-redux";
import { Store } from "redux";
import { Grid } from "theme-ui";
import { v4 as uuidv4 } from "uuid";
import { addCopy, clearCopy } from "../../actions/copy";
import { addObjects, deleteObjects } from "../../actions/objects";
import { getCanvas } from "../../design/canvas";
import { RootState } from "../../lib/configureStore";
import { applyControls } from "../../lib/controls/fabric/onlyrotate";
import { EcogardenCanvas } from "../../lib/fabric";
import { setSelection } from "../../lib/fabric/canvas";
import {
  EcogardenFabricObjects,
  toFabricObject,
} from "../../lib/fabric/objects";
import log from "../../lib/log";
import { EcogardenObjects } from "../../lib/objects";
import IconButton from "../buttons/IconButton";
// import useKeybind, { KeyBinding } from "use-keybinds/useKeybind";
import useKeybind, { Keybinding } from "@utilityjs/use-keybind/useKeybind";
// import useKeybind, { KeyBinding } from "@rockerboo/use-keybind";

export const cutHandler =
  (onCut?: (objects: readonly EcogardenObjects[]) => void) =>
  (store: Store<RootState>) =>
  (canvas?: EcogardenCanvas) =>
  (e: Event): void => {
    //
    if (!canvas) {
      return;
    }

    const ids = new Set(
      canvas.getActiveObjects().map((obj) => {
        obj.setCoords();
        return obj.id;
      })
    );
    const objectsToCut = store
      .getState()
      .objects.present.filter(({ id }) => ids.has(id));

    log.info("cut (ecogarden)", () =>
      objectsToCut.map(({ top, left, originX, originY }) => [
        top,
        left,
        originX,
        originY,
      ])
    );

    canvas.getActiveObjects().forEach((obj) => {
      canvas.fxRemove(obj);
      store.dispatch(deleteObjects(objectsToCut.map(({ id }) => id)));
    });
    canvas.requestRenderAll();

    canvas.discardActiveObject(e);

    onCut && onCut(objectsToCut);

    batch(() => {
      store.dispatch(clearCopy());
      store.dispatch(addCopy(objectsToCut));
    });
  };

// undo and redo events
export const copyHandler =
  (onCopy?: (objects: readonly EcogardenObjects[]) => void) =>
  (store: Store<RootState>) =>
  (canvas?: EcogardenCanvas) =>
  (e: {}): void => {
    if (!canvas) {
      return;
    }

    // get selection
    const ids = canvas
      .getActiveObjects()
      .map((obj: Readonly<EcogardenFabricObjects>) => {
        // obj.setCoords();
        return obj.id;
      });

    log.debug("ids to copy", ids);

    const objectsToCopy = store
      .getState()
      .objects.present.filter(({ id }) => ids.includes(id));

    log.info(
      "copied (ecogarden)",
      ...objectsToCopy.map(({ top, left }) => [top, left])
    );

    onCopy && onCopy(objectsToCopy);

    batch(() => {
      store.dispatch(clearCopy());
      store.dispatch(addCopy(objectsToCopy));
    });
  };

const makeSelection =
  (canvas: Readonly<EcogardenCanvas>) =>
  (fabricObjects: readonly Readonly<EcogardenFabricObjects>[]) =>
  (e?: Readonly<Event>) => {
    const newSelection = setSelection(canvas)(fabricObjects)(e);
    applyControls({})(newSelection);
    canvas.requestRenderAll();
  };

type Callbacks = {
  readonly onComplete?: (endValue: number) => void;
  readonly onChange?: (current: number) => void;
};

const fxAdd =
  ({ onComplete, onChange }: Callbacks) =>
  (canvas: Readonly<EcogardenCanvas>) =>
  (obj: Readonly<EcogardenFabricObjects>): Promise<EcogardenFabricObjects> => {
    // @ts-ignore
    obj.set("opacity", 0);
    canvas.add(obj);

    // eslint-disable-next-line compat/compat
    return new Promise((resolve) => {
      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: 240,
        onChange: (current) => {
          // @ts-ignore
          obj.set("opacity", current);
          canvas.requestRenderAll();
          onChange && onChange(current);
        },
        onComplete: (endValue) => {
          // @ts-ignore
          obj.set("opacity", 1);
          canvas.requestRenderAll();
          resolve(obj);
          onComplete && onComplete(endValue);
        },
      });
    });
  };

/**
 * Add an object to the canvas
 */
const addObjectToCanvas =
  (canvas: EcogardenCanvas) =>
  (obj: EcogardenFabricObjects): Promise<EcogardenFabricObjects> => {
    // // @ts-ignore
    // obj.set("evented", true);

    // // @ts-ignore
    // obj.setCoords();

    // canvas.add(obj);

    // canvas
    //   .getObjects()
    //   .filter((o) => o.id === obj.id)
    //   .forEach((o) => {
    //     console.log("top, left", o.left, o.top);
    //   });

    // return Promise.resolve(obj);
    //

    return fxAdd({})(canvas)(obj);
  };

/**
 * Offset object by amount (useful for moving for pasting new objects of copied objects
 */
const setObjectOffset = (amount: number) => (obj: EcogardenObjects) => {
  // Offset new objects
  const left = obj.left ? obj.left + amount : 0;
  const top = obj.top ? obj.top + amount : 0;

  return { ...obj, left, top };
};

/**
 * Set the object uuid id to the object
 */
const setObjectId = (id: string) => (obj: EcogardenObjects) => {
  return { ...obj, id };
};

// const getOriginalObjectTopLeft = (
//   object: EcogardenFabricObjects
// ): fabric.Point => {
//   const matrix = object.calcTransformMatrix();
//   // 2. choose the point you want, fro example top, left.
//   const point = {
//     // x: -fromActiveObjects.width / 2,
//     // y: fromActiveObjects.height / 2,
//     x: 0,
//     y: 0,
//   };

//   // 3. transform the point
//   const pointOnCanvas = fabric.util.transformPoint(point, matrix);

//   return pointOnCanvas;
// };

/**
 * Handle paste action on the canvas
 */
export const pasteHandler =
  (onPaste?: (objects: readonly EcogardenObjects[]) => void) =>
  (store: Store<RootState>) =>
  (canvas?: EcogardenCanvas) =>
  (e: React.MouseEvent<Element, MouseEvent> | KeyboardEvent): void => {
    if (!canvas) {
      return;
    }

    log.debug("copy buffer", store.getState().copy);

    // Create new objects, give id, and move somewhere not on the copied location.
    const newObjects = store.getState().copy.map((obj) => {
      return setObjectId(uuidv4())(setObjectOffset(25)(obj));
    });

    // TODO: Having or selecting these objects is causing side effects where objects move to a selection based location.
    canvas.discardActiveObject();

    // Load up new objects
    // Add them to the canvas (animated)
    // Then make selection on new objects
    // Then fire onPaste callback with new Ecogarden Objects
    Promise.all(newObjects.map(toFabricObject)).then((fabricObjects) => {
      log.info("pasted (fabric)", () =>
        fabricObjects.map(({ top, left, originX, originY }) => [
          top,
          left,
          originX,
          originY,
        ])
      );
      return Promise.all(fabricObjects.map(addObjectToCanvas(canvas)))
        .then((fabricObjects) => {
          makeSelection(canvas)(fabricObjects)();

          log.debug("new objects", newObjects);

          // FIXME:
          // adding this causes the reconcilation to happen elsewhere and moves the objects into the incorrect location.
          // Sometimes also moves the objects around
          store.dispatch(addObjects(newObjects));
          log.debug(
            "objects found in paste also found in objects present",
            () => {
              const ecogardenObjects = store.getState().objects.present;
              const list = ecogardenObjects.filter((a) =>
                fabricObjects.some(({ id }) => id === a.id)
              );

              return list;
            }
          );
          onPaste && onPaste(newObjects);
        })
        .catch((e) => {
          console.error("Could not paste properly", e);
        });

      // makeSelection(canvas)(
      //   canvas._objects.filter((o) => fabricObjects.some((fo) => fo.id == o.id))
      // )(e as unknown as Event);
      // canvas.requestRenderAll();
      // // TODO:
      // // adding this causes the reconcilation to happen elsewhere and moves the objects into the incorrect location.
      // // Sometimes also moves the objects around
      // store.dispatch(addObjects(newObjects));
      // onPaste && onPaste(newObjects);
    });
  };

// Select all the objects on the canvas
const selectAllHandler = (canvas?: EcogardenCanvas) => (): void => {
  if (!canvas) {
    return;
  }

  // get selection
  const objects = canvas.getObjects().filter(({ id }) => id !== undefined);
  makeSelection(canvas)(objects);
};

const duplicateHandler =
  (store: Store<RootState>) =>
  (canvas?: EcogardenCanvas) =>
  (e: React.MouseEvent<Element, MouseEvent>): void => {
    if (!canvas) {
      return;
    }
    // copy the selected item
    // then paste
    copyHandler()(store)(canvas)(e);
    pasteHandler()(store)(canvas)(e);
  };

type Props = {
  readonly onCopy?: (objects: readonly EcogardenObjects[]) => void;
  readonly onCut?: (objects: readonly EcogardenObjects[]) => void;
  readonly onPaste?: (objects: readonly EcogardenObjects[]) => void;
};

type HasSelectionProp = {
  readonly hasSelection?: boolean;
};

const CopyTool: React.FunctionComponent<Props & HasSelectionProp> = ({
  onCopy,
  onCut,
  onPaste,
  hasSelection,
}) => {
  const [copyDebug, setCopyDebug] = useState(false);
  // const [currentlyHasSelection, setHasSelection] = useState(
  //   hasSelection ?? false
  // );
  const store = useStore<RootState>();
  const { canvas, copy, objects } = useSelector((state: RootState) => ({
    canvas: getCanvas(state.canvas) as EcogardenCanvas | undefined,
    copy: state.copy,
    objects: state.objects.present,
  }));

  // eslint-disable-next-line functional/prefer-readonly-type
  const keybinds: Keybinding[] = [
    { keys: ["Control", "c"], callback: copyHandler(onCopy)(store)(canvas) },
    { keys: ["Control", "x"], callback: cutHandler(onCut)(store)(canvas) },
    { keys: ["Control", "v"], callback: pasteHandler(onPaste)(store)(canvas) },
  ];

  useKeybind(window, keybinds);

  // useHotkeys("ctrl+c", copyHandler(onCopy)(store)(canvas), {  }, [
  //   canvas,
  //   store,
  //   onCopy,
  // ]);

  // useHotkeys("ctrl+x", cutHandler(onCut)(store)(canvas), {  }, [
  //   canvas,
  //   store,
  //   onCopy,
  // ]);

  // useHotkeys("ctrl+v", pasteHandler(onPaste)(store)(canvas), {  }, [
  //   canvas,
  //   store,
  //   onPaste,
  // ]);

  useHotkeys("shift+c", () => {
    setCopyDebug((v) => (v ? false : true));
  });

  if (copyDebug) {
    return (
      <Grid
        sx={{
          fontSize: 0,
          backgroundColor: "background",
          position: "absolute",
          bottom: 100,
          pointerEvents: "none",
          fontFamily: "monospace",
          gridAutoFlow: "column",
          gridGap: 2,
        }}
      >
        {copy.map((obj) => {
          // const fabricObject = canvas
          //   ?.getObjects()
          //   .find(({ id }) => id === obj.id)
          //   ?.toObject(["id"]);
          const ecogardenObject = objects.find(({ id }) => id === obj.id);

          // eslint-disable-next-line react/display-name
          const diffHi = (eoTop?: number) => (foTop?: number) => {
            if (eoTop && foTop && eoTop != foTop) {
              return (
                <span
                  sx={{
                    color: "red",
                    backgroundColor: "background",
                    padding: 1,
                  }}
                >
                  {eoTop.toFixed(3)} {foTop.toFixed(3)}
                </span>
              );
            }

            return eoTop?.toFixed(3);
          };
          return (
            <Grid
              sx={{
                gridTemplateColumns: "subgrid",
                // gridAutoFlow: "column",
                textAlign: "center",
                gridGap: 0,
                backgroundColor: "accent-bg",
              }}
              key={obj.id}
            >
              <div>{obj.id.substring(0, 8)}</div>
              <div>{obj.subtype}</div>
              <div>{diffHi(obj?.top)(ecogardenObject?.top)}</div>
              <div>{diffHi(obj?.left)(ecogardenObject?.left)}</div>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return hasSelection ? (
    <Grid sx={{ justifyContent: "flex-end" }}>
      <IconButton
        onClick={(e) => duplicateHandler(store)(canvas)(e)}
        icon={Copy}
        label="Duplicate"
        tippyProps={{ placement: "left" }}
      />
    </Grid>
  ) : null;
};

export default CopyTool;
