/* eslint-disable max-lines-per-function */
import { fabric } from "fabric";
import React from "react";
import { connect, useStore } from "react-redux";
import { Box } from "theme-ui";
import { updateViewport } from "../../actions/viewport";
import { getCanvas } from "../../design/canvas";
import { constrain, objectsBoundaries } from "../../lib/boundaries";
import type { RootState } from "../../lib/configureStore";
import { updateGridBackground } from "../../lib/fabric/viewport";
import FitToIcon from "../icons/fit-to.svg";
import ToolButton from "./ToolButton";

type Point = { readonly x: number; readonly y: number };
export const diff =
  (p1: Point) =>
  (p2: Point): Point => ({
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  });

type TopLeft = { readonly left: number; readonly top: number };

export const diffCenter =
  (p1: Point) =>
  (p2: TopLeft): Point => ({
    x: p2.left - p1.x,
    y: p2.top - p1.y,
  });

type TopLeftWidthHeight = {
  readonly left: number;
  readonly top: number;
  readonly width: number;
  readonly height: number;
};

export const rect =
  (tl: fabric.Point | Point) =>
  (br: fabric.Point | Point): TopLeftWidthHeight => {
    return { top: tl.y, left: tl.x, width: br.x - tl.x, height: br.y - tl.y };
  };

/**
 * Process objects and fit them to the canvas
 */
export const fitToViewport =
  (canvas: fabric.Canvas) =>
  (
    objects: readonly Readonly<fabric.Object>[]
  ): undefined | readonly [number, number, number, number, number] => {
    if (!canvas.viewportTransform) {
      return;
    }

    const boundary = objectsBoundaries(
      objects.filter(({ id }) => id !== undefined)
    );

    if (!boundary || boundary.length === 0) {
      return;
    }

    const [tlx, tly, brx, bry] = boundary;

    const padding = 50;
    const box = {
      left: tlx - padding,
      top: tly - padding,
      width: brx - tlx + padding * 2,
      height: bry - tly + padding * 2,
    };

    const canvasBox = {
      left: 0,
      top: 0,
      width: canvas.getWidth(),
      height: canvas.getHeight(),
    };

    const [x, y, w, h, s] = constrain(box)(canvasBox);

    canvas.setZoom(s);
    canvas.absolutePan({ x: -x + box.left * s, y: -y + box.top * s });
    canvas.renderAll();

    if (canvas.viewportTransform) {
      updateGridBackground(canvas.viewportTransform);
    }

    return [x, y, w, h, s];
    // return {
    //   x: 0,
    //   y: 0,
    //   zoom: 1,
    // };
    // return {
    //   x: objectsCenterPoint.x,
    //   y: objectsCenterPoint.y,
    //   zoom: zoomLevel,
    // };
  };

const FitToViewport: React.FunctionComponent<
  Readonly<{
    readonly canvas?: fabric.Canvas;
    // eslint-disable-next-line max-lines-per-function
  }>
> = ({ canvas }) => {
  const store = useStore();

  const handleFitToScreen: React.MouseEventHandler = (e): void => {
    if (!canvas || !canvas.viewportTransform) {
      return;
    }

    // canvas.setZoom(1);
    const newPosition = fitToViewport(canvas)(canvas.getObjects());

    if (!newPosition) {
      return;
    }

    store.dispatch(updateViewport(canvas.viewportTransform));

    updateGridBackground(canvas.viewportTransform);
    canvas.requestRenderAll();
    (e.target as HTMLButtonElement).blur();
  };

  return (
    <Box sx={{ gridArea: "fit", padding: 1 }}>
      <ToolButton
        data-testid="fitToViewport"
        label={`Fit to screen`}
        icon={FitToIcon}
        type="button"
        size={24}
        tippyProps={{ placement: "top" }}
        onClick={handleFitToScreen}
      />
    </Box>
  );
};

export default connect((state: Readonly<RootState>) => ({
  canvas: getCanvas(state.canvas),
}))(FitToViewport);
