/* eslint-disable babel/no-unused-expressions */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import type { fabric } from "fabric";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCanvas } from "../../design/canvas";
import { RootState } from "../../lib/configureStore";
import { toPoint } from "../../lib/dom";
import type { DragEventCanvas, DragFabricEvent } from "../../lib/fabric/drag";
import {
	ZoomFabricEvent,
	ZoomFabricEventHandlers,
} from "../../lib/fabric/zoom";
import { PIXELS_PER_FEET } from "../../lib/objects";
import { clamp, diff } from "../../lib/vector";
import { getAvailableSizes, getMaxSize, getMinSize, Shape } from "../../shapes";

/**
 * Handle constraining scaling parameters when modifying via the canvas (touch events or using controls)
 */
export const handleConstrainingScaling = (
	event: Readonly<DragFabricEvent<fabric.Object>>
) => {
	// We do no allow textbox to scale so if it is scaling then we will reset it to 0.
	// We should be preventing the textbox from scaling at all
	if (event.target.type === "textbox") {
		event.target.set({ scaleX: 1, scaleY: 1 });
		return;
	}

	const subtype = event.target.subtype as Shape | undefined;
	const sizes = getAvailableSizes(subtype);

	sizes.forEach((size) => {
		const min = getMinSize(subtype)(size) * PIXELS_PER_FEET;
		const max = getMaxSize(subtype)(size) * PIXELS_PER_FEET;

		if (size == "width") {
			if (event.target.scaleX && event.target.width) {
				// minimum and maximum sizes for objects
				if (
					event.target.width * event.target.scaleX < min ||
					event.target.width * event.target.scaleX > max
				) {
					const scaleX = clamp(
						event.target.width * event.target.scaleX,
						min,
						max
					);

					event.target.set({
						scaleX: scaleX / event.target.width,
					});
					typeof event.target.calcCoords === "function" &&
						event.target.calcCoords();
					event.target.canvas?.requestRenderAll();
				}
			}
		} else if (size == "depth") {
			if (event.target.scaleY && event.target.width && event.target.height) {
				// minimum and maximum sizes for objects
				if (
					event.target.height * event.target.scaleY < min ||
					event.target.height * event.target.scaleY > max
				) {
					const scaleY = clamp(
						event.target.height * event.target.scaleY,
						min,
						max
					);

					event.target.set({
						scaleY: scaleY / event.target.height,
					});
					typeof event.target.calcCoords === "function" &&
						event.target.calcCoords();
					event.target.canvas?.requestRenderAll();
				}
			}
		} else {
			// size = 'size'
			// width, height, scaleX, scaleY

			if (
				event.target.scaleX &&
				event.target.scaleY &&
				event.target.width &&
				event.target.height
			) {
				// minimum and maximum sizes for objects
				if (
					event.target.width * event.target.scaleX < min ||
					event.target.width * event.target.scaleX > max ||
					event.target.height * event.target.scaleY < min ||
					event.target.height * event.target.scaleY > max
				) {
					const scaleX = clamp(
						event.target.width * event.target.scaleX,
						min,
						max
					);

					const scaleY = clamp(
						event.target.height * event.target.scaleY,
						min,
						max
					);

					// check that the scale is not outside the bounds
					event.target.set({
						scaleX: scaleX / event.target.width,
						scaleY: scaleY / event.target.height,
					});
					typeof event.target.calcCoords === "function" &&
						event.target.calcCoords();
					event.target.canvas?.requestRenderAll();
				}
			}
		}
	});
};

const Gesture = () => {
	// const dispatch = useDispatch();
	const { canvas } = useSelector((state: RootState) => {
		return {
			canvas: getCanvas(
				state.canvas
			) as ZoomFabricEventHandlers<fabric.Canvas> &
				fabric.Canvas & {
					readonly upperCanvasEl: HTMLCanvasElement;
				},
		};
	});

	useEffect(() => {
		if (!canvas) {
			return;
		}

		let tpCache: Pick<Touch, "identifier" | "clientX" | "clientY">[] = [];

		(canvas as unknown as DragEventCanvas<fabric.Object> & fabric.Canvas).on(
			"object:scaling",
			handleConstrainingScaling
		);

		/**
		 * Handle touch gestures on the canvas
		 */
		const handleGesture = (event: Readonly<ZoomFabricEvent>) => {
			// If we have a target then we shouldn't handle basic gestures.
			if (event.target) {
				return;
			}

			const fingers = event?.self!.fingers;
			if (fingers >= 2) {
				canvas.selection = false;

				const ev = event.e as TouchEvent;

				if (event.e.type == "touchstart") {
					ev.preventDefault();
					// Cache the touch points for later processing of 2-touch pinch/zoom
					if (ev.targetTouches.length == 2) {
						for (var i = 0; i < ev.targetTouches.length; i++) {
							tpCache.push(ev.targetTouches[i]);
						}
					}
				}

				// Check if the two target touches are the same ones that started
				// the 2-touch
				var point1 = -1,
					point2 = -1;
				for (var i = 0; i < tpCache.length; i++) {
					if (tpCache[i].identifier == ev.targetTouches[0].identifier)
						point1 = i;
					if (tpCache[i].identifier == ev.targetTouches[1].identifier)
						point2 = i;
				}

				if (point1 >= 0 && point2 >= 0) {
					// This threshold is device dependent as well as application specific

					const diff1 = diff(toPoint(tpCache[point1]))(
						toPoint(ev.targetTouches[0])
					);
					const diff2 = diff(toPoint(tpCache[point2]))(
						toPoint(ev.targetTouches[1])
					);
					// const middleOf = middle(toPoint(tpCache[point2]))(
					//   toPoint(ev.targetTouches[1])
					// );
					const PINCH_THRESHOLD = 25;
					if (diff1 >= PINCH_THRESHOLD && diff2 >= PINCH_THRESHOLD) {
						const ignoreZoom = true;
						const point = canvas.getPointer(event.e, ignoreZoom);
						canvas.zoomToPoint(point as fabric.Point, (1 * diff1) / 50);
						canvas.requestRenderAll();
					}
				}

				// Finishing touch of 2 fingers, reset canvas and cache
				if (ev.type === "touchend") {
					// Re-enable selection on the canvas
					canvas.selection = true;

					// empty tpCache
					tpCache = [];
				}

				// Drag Touch without 2 fingers
			} else {
				// Touching end should make sure the canvas and cache should reset as you might release 1 finger first.
				if (event.e.type === "touchend") {
					tpCache = [];
				}
			}
		};

		/**
		 * Handle touch drag gestures on the canvas
		 */
		const handleDrag = (e: Readonly<ZoomFabricEvent>) => {
			if (e.e.type === undefined) {
				// final touch
				// console.log("clearing cache on final change?", e.e);
				tpCache = new Array();
				return;
			}
			// const self = e?.self;
			// console.log(
			//   "drag",
			//   self?.x, // centroid
			//   self?.y,
			//   self?.rotation,
			//   self?.scale,
			//   self?.fingers,
			//   self?.state
			// );
			// console.log("drag", e.self);
			// const fingers = e?.self!.fingers;

			if (e?.self?.fingers && e.self.fingers >= 2) {
				if (e.e.type === "touchend") {
					// console.log("cache cleared");
					tpCache = new Array();
				}
			} else {
				if (tpCache.length > 0) {
					// console.log("non-empty cache");
				}

				if (e.e.type === "touchend") {
					// console.log("<2 touches, cache clear");
					tpCache = new Array();
				}

				if (e.e.type === undefined) {
					// console.log("changed touch?");
					tpCache = new Array();
				}
			}

			// if (fingers >= 2) {
			//   canvas.selection = false;
			//   if (info) {
			//     info.innerHTML = "pinch/rotate";
			//     const text = document.createTextNode(" fingers ");
			//     info.insertBefore(text, info!.firstChild);
			//   }
			//   const ev = e.e as TouchEvent;

			//   if (e.e.type == "touchstart") {
			//     ev.preventDefault();
			//     // Cache the touch points for later processing of 2-touch pinch/zoom
			//     if (ev.targetTouches.length == 2) {
			//       for (var i = 0; i < ev.targetTouches.length; i++) {
			//         tpCache.push(ev.targetTouches[i]);
			//       }
			//       console.log("initial state", tpCache);
			//     }
			//   }

			//   // If we have enough touches (not sure if needed due to checking fingers >= 2 above)
			//   if (
			//     ev.targetTouches &&
			//     ev.targetTouches.length == 2 &&
			//     ev.changedTouches.length == 2
			//   ) {
			//     // Check if the two target touches are the same ones that started
			//     // the 2-touch
			//     var point1 = -1,
			//       point2 = -1;
			//     for (var i = 0; i < tpCache.length; i++) {
			//       if (tpCache[i].identifier == ev.targetTouches[0].identifier)
			//         point1 = i;
			//       if (tpCache[i].identifier == ev.targetTouches[1].identifier)
			//         point2 = i;
			//     }
			//     if (point1 >= 0 && point2 >= 0) {
			//       // // Calculate the difference between the start and move coordinates
			//       // var diff1 = Math.sqrt(
			//       //   Math.pow(tpCache[point1].clientX - ev.targetTouches[0].clientX, 2)
			//       // );
			//       // var diff2 = Math.abs(
			//       //   tpCache[point2].clientX - ev.targetTouches[1].clientX
			//       // );

			//       // var diff3 = Math.abs(
			//       //   tpCache[point1].clientY - ev.targetTouches[0].clientY
			//       // );
			//       // var diff4 = Math.abs(
			//       //   tpCache[point2].clientY - ev.targetTouches[1].clientY
			//       // );

			//       // @ts-ignore
			//       // console.log(ev.target!.clientWidth, ev.target!.clientHeight)
			//       // This threshold is device dependent as well as application specific

			//       const diff1 = diff(tpCache[point1])(toPoint(ev.targetTouches[0]));
			//       const diff2 = diff(tpCache[point2])(toPoint(ev.targetTouches[1]));
			//       const middleOf = middle(tpCache[point2])(
			//         toPoint(ev.targetTouches[1])
			//       );
			//       const PINCH_THRESHOLD = 25;
			//       if (diff1 >= PINCH_THRESHOLD && diff2 >= PINCH_THRESHOLD) {
			//         // @ts-ignore
			//         // ev.target!.style.background = "green";

			//         info!.innerHTML = `${zoomCache} ${diff1} ${diff2} ${middleOf[0]} ${middleOf[1]}`;
			//         const text = document.createTextNode(" fingers ");
			//         info!.insertBefore(text, info!.firstChild);

			//         const ignoreZoom = true;
			//         const point = canvas.getPointer(e.e, ignoreZoom);
			//         // new fabric.Point(middleOf[0], middleOf[1]),
			//         canvas.zoomToPoint(point as fabric.Point, (1 * diff1) / 50);
			//         canvas.requestRenderAll();
			//         // canvas.setZoom((1 * diff1) / 50).requestRenderAll();
			//       }
			//       // console.log("hello fom the pincher master");
			//     } else {
			//       console.log(`clear cache touch points too close`);
			//       // empty tpCache
			//       tpCache = new Array();
			//     }
			//     // Not 2 finger touch, reset canvas and cache
			//   } else {
			//     canvas.selection = true;

			//     console.log(`clear cache ${self?.fingers} fingers pressed`);

			//     // empty tpCache
			//     tpCache = new Array();
			//   }

			//   // Finishing touch of 2 fingers, reset canvas and cache
			//   if (ev.type === "touchend") {
			//     console.log(ev.type, ev);
			//     // Re-enable selection on the canvas
			//     canvas.selection = true;
			//   }

			//   // Drag Touch without 2 fingers
			// } else {
			//   // Touching end should make sure the canvas and cache should reset as you might release 1 finger first.
			//   if (e.e.type === "touchend") {
			//     console.log(e.e.type, e.e);
			//     (e.e!.target as HTMLCanvasElement).style.background = "white";
			//   }

			//   if (info) {
			//     info!.innerHTML = `drag ${fingers} ${e?.self!.state}`;
			//     const text = document.createTextNode(" Dragging ");
			//     info!.insertBefore(text, info!.firstChild);
			//   }
			//   console.log("clear cache < 2 fingers pressed");
			//   tpCache = new Array();
			// }
		};

		/**
		 * Handle touch long press gesture
		 */
		const handleLongPress = (e: ZoomFabricEvent) => {};

		/**
		 * Handle touch swipe gesture
		 */
		const handleSwipe = (e: ZoomFabricEvent) => {};

		canvas.on({
			"touch:gesture": handleGesture,
			"touch:drag": handleDrag,
			"touch:swipe": handleSwipe,
			"touch:longpress": handleLongPress,
		});

		return function cleanup() {
			canvas.off({
				"touch:gesture": handleGesture,
				"touch:drag": handleDrag,
				"touch:swipe": handleSwipe,
				"touch:longpress": handleLongPress,
			});
		};
	});

	return <></>;
};

export default Gesture;
