import { fabric } from "fabric";

// How to set additional properties in all fabric.Object's
// https://github.com/fabricjs/fabric.js/wiki/How-to-set-additional-properties-in-all-fabric.Objects
const originalToObject = fabric.Object.prototype.toObject;

const myAdditional: string[] = [
	"id",
	"subtype",
	"points",
	"pathOffset",
	"rx",
	"ry",
];

fabric.Object.prototype.toObject = function (
	additionalProperties?: readonly string[]
) {
	if (!additionalProperties) {
		return originalToObject.call(this);
	}
	return originalToObject.call(this, myAdditional.concat(additionalProperties));
};

/**
 * Setup defaults for control and visual styling
 */
fabric.Object.prototype.strokeWidth = 1;
fabric.Object.prototype.borderScaleFactor = 3;
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerSize = 44;
fabric.Object.prototype.touchCornerSize = 44;
fabric.Object.prototype.cornerStyle = "circle";
fabric.Object.prototype.padding = 22;

// Use up to this fraction digits for numbers inside objects
// fabric.Object.NUM_FRACTION_DIGITS = 8;

// Use CSS custom properties
const styles = getComputedStyle(document.documentElement);
if (styles) {
	fabric.Object.prototype.borderColor =
		styles.getPropertyValue("--accent-fg-color");
	fabric.Object.prototype.cornerColor =
		styles.getPropertyValue("--bg-color") + "99";
	fabric.Object.prototype.cornerStrokeColor =
		styles.getPropertyValue("--accent-fg-color");
}
