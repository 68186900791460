import type { fabric } from "fabric";
import React, { useEffect, useMemo } from "react";
import tippy, { followCursor } from "tippy.js";
import { getCanvas } from "../../design/canvas";
import { useAppSelector } from "../../lib/configureStore";
import {
	addLock,
	EcogardenFabricObject,
	selectionText,
} from "../../lib/fabric/objects";
import { addSize, findObject } from "../../lib/objects";

/**
 * Adds a tooltip to fabric.Canvas.
 */
// eslint-disable-next-line max-lines-per-function
const Tooltip: React.FunctionComponent = () => {
	const [canvas, objects] = useAppSelector((state) => [
		getCanvas(state.canvas),
		state.objects.present,
	]);
	const tippyInstance = useMemo(
		() =>
			tippy(document.createElement("canvas"), {
				followCursor: true,
				plugins: [followCursor],
				trigger: "manual",
				placement: "left",
				animation: false,
				appendTo: document.getElementById("tooltips-container") ?? undefined,
				hideOnClick: "toggle",
				duration: 0,
			}),
		[]
	);

	// eslint-disable-next-line max-lines-per-function
	useEffect(() => {
		if (!canvas) {
			return;
		}

		// eslint-disable-next-line complexity
		const show = async (e: fabric.IEvent): Promise<void> => {
			if (!e.target) {
				if (tippyInstance.state.isShown) {
					tippyInstance.hide();
				}
				return;
			}

			const target = e.target as EcogardenFabricObject;
			const object = findObject(objects)(target["id"]);

			if (!object) {
				if (tippyInstance.state.isShown) {
					tippyInstance.hide();
				}
				return;
			}

			const size = addSize(object);
			const lock = addLock(target);

			// Don't show size for polygons or textbox for now.
			tippyInstance.setContent(
				target.type === "polygon" || target.type === "textbox"
					? lock(object.label ?? selectionText(target))
					: lock(`${object.label ?? selectionText(target)} — ${size}`)
			);

			// if (!tippyInstance.state.isShown) {
			tippyInstance.show();
			// }
		};

		const hide = async (): Promise<void> => {
			// if (tippyInstance.state.isShown) {
			tippyInstance.hide();
			// }
		};

		// Removing due to not required?
		// const objectModified = (): void => {
		//   tippyInstance.show();
		// };

		canvas.on("mouse:over", show);
		canvas.on("mouse:out", hide);
		canvas.on("selection:cleared", hide);
		// canvas.on("object:modified", objectModified);

		return function cleanup(): void {
			canvas.off("mouse:over", show);
			canvas.off("mouse:out", hide);
			canvas.off("selection:cleared", hide);
			// canvas.off("object:modified", objectModified);
			tippyInstance.hide();
		};
	}, [tippyInstance, canvas, objects]);

	return <></>;
};

export default Tooltip;
