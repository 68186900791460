import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import { Box } from "theme-ui";
import { deleteObjects } from "../../actions/objects";
import { closeAll } from "../../actions/panels";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import { EcogardenFabricObject, removeObjects } from "../../lib/fabric/objects";
import ConfirmModal from "../modals/ConfirmModal";
import Trash from "./Trash";

const removeSelection =
  (canvas: fabric.Canvas) =>
  (dispatch: Dispatch): void => {
    // Remove objects from canvas, delete objects from store
    Promise.all(
      removeObjects(
        canvas.getActiveObjects() as readonly EcogardenFabricObject[]
      )
    ).then((ids) => {
      dispatch(
        deleteObjects([
          ...(ids.filter((id) => id !== undefined) as readonly string[]),
        ])
      );
    });

    // Remove selection
    // canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

const TrashContainer: React.FunctionComponent<{
  readonly canvas: fabric.Canvas | undefined;
  // eslint-disable-next-line max-lines-per-function
}> = ({ canvas }) => {
  const dispatch = useDispatch();
  const [isConfirming, setIsConfirming] = useState(false);
  const [show, setShow] = useState(false);
  const handleTrashClick = (): void => setIsConfirming(true);

  const handleConfirm = (): void => {
    if (!canvas) {
      return;
    }

    removeSelection(canvas)(dispatch);
    setIsConfirming(false);
  };

  const onClose = (): void => {
    dispatch(closeAll());
  };

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleShow = (): void => setShow(true);
    const handleHide = (): void => setShow(false);

    canvas.on("selection:created", handleShow);
    canvas.on("selection:cleared", handleHide);

    return function cleanup(): void {
      canvas.off("selection:created", handleShow);
      canvas.off("selection:cleared", handleHide);
    };
  }, [canvas]);

  return (
    <Box
      className="TrashContainer"
      sx={{
        margin: 2,
        lineHeight: "initial",
        gridArea: "trash",
      }}
    >
      {show && <Trash onTrashClick={handleTrashClick} />}
      <ConfirmModal
        caution
        isOpen={isConfirming}
        onRequestClose={(): void => setIsConfirming(false)}
        onConfirm={handleConfirm}
        onCancel={onClose}
        actionText="Remove"
        actionProps={{ variant: "alert" }}
      >
        Remove these shapes?
      </ConfirmModal>
    </Box>
  );
};

export default connect((state: RootState) => ({
  canvas: getCanvas(state.canvas),
}))(TrashContainer);
