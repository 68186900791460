/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ZoomOut as ZoomOutIcon } from "@styled-icons/feather/ZoomOut";
import { fabric } from "fabric";
import React, { useEffect } from "react";
import { connect, useDispatch, useStore } from "react-redux";
import type { Store } from "redux";
import { getCanvas } from "../../design/canvas";
import { canvasZoom, canvasZoomToPoint } from "../../lib/canvas";
import type { RootState } from "../../lib/configureStore";
import { EcogardenFabricObjects } from "../../lib/fabric/objects";
import {
  calculateNewZoomLevel,
  OSMZoomLevelToFabricZoomLevel,
  subtract,
  ZoomFabricEvent,
  ZoomFabricEventHandlers,
} from "../../lib/fabric/zoom";
import log from "../../lib/log";
import {
  clampZoom,
  wheelZoomDirection,
  ZoomDirection,
  zoomOutOfBounds,
} from "../../lib/zoom";
import ToolButton from "./ToolButton";

export type ZoomEventCanvas = ZoomFabricEventHandlers<fabric.Canvas> &
  fabric.Canvas & {
    readonly upperCanvasEl: HTMLCanvasElement;
  };

/**
 * Handle zoom to point
 * Used for fabric mouse:wheel
 */
export const handleWheelZoom =
  (canvas: ZoomFabricEventHandlers<fabric.Canvas> & fabric.Canvas) =>
  (store: Store) =>
  (e: fabric.IEvent): void => {
    if (!canvas) {
      console.error("invalid canvas to handle wheel zoom");
      return;
    }

    const event_ = e.e as WheelEvent;

    // Skip if using ctrl (browser scroll)
    if (event_.ctrlKey) {
      return;
    }

    const { zoom } = store.getState();

    const zoomDirection =
      wheelZoomDirection(event_) === ZoomDirection.Out ? -1 : 1;

    const newZoomLevel = zoom + zoomDirection;
    const point = canvas.getPointer(e.e, true);

    // TODO replace with user's latitude
    const lat = 45;

    if (zoomOutOfBounds(newZoomLevel)) {
      // store.dispatch(zoomActions.zoom(clampZoom(newZoomLevel)));
      const fabricZoomLevel = OSMZoomLevelToFabricZoomLevel(lat)(
        clampZoom(newZoomLevel)
      );
      // console.log("clamping zoom", newZoomLevel, clampZoom(newZoomLevel));
      canvasZoomToPoint(canvas)(store.dispatch)(fabricZoomLevel)(point);
      return;
    }

    const fabricZoomLevel = OSMZoomLevelToFabricZoomLevel(lat)(newZoomLevel);

    log.debug(
      "zoomToPoint",
      "point",
      point.x,
      point.y,
      "newLevel",
      newZoomLevel,
      fabricZoomLevel
    );

    canvasZoomToPoint(canvas)(store.dispatch)(fabricZoomLevel)(point);
  };

/**
 * Handle zoom out events
 */
export const handleZoomOut =
  (canvas: fabric.Canvas) => (store: Store<RootState>) => (): void => {
    const newZoomLevel = calculateNewZoomLevel(subtract)(store.getState().zoom);

    // TODO: Use user's latitude
    const latitude = 45;

    if (zoomOutOfBounds(newZoomLevel)) {
      const fabricZoomLevel = OSMZoomLevelToFabricZoomLevel(latitude)(
        clampZoom(newZoomLevel)
      );
      canvasZoom(canvas)(store.dispatch)(fabricZoomLevel);
      return;
    }

    canvasZoom(canvas)(store.dispatch)(
      OSMZoomLevelToFabricZoomLevel(latitude)(newZoomLevel)
    );
  };

/**
 * zoom and canvas props from the store
 */
interface ZoomOutProperties extends Pick<RootState, "zoom"> {
  readonly canvas?: ZoomFabricEventHandlers<fabric.Canvas> &
    fabric.Canvas & { readonly upperCanvasEl: HTMLCanvasElement };
}

/**
 * ZoomOut button
 */
const ZoomOut: React.FunctionComponent<ZoomOutProperties> = ({
  canvas,
  zoom: _zoom,
}) => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleDoubleClick = (e: ZoomFabricEvent) => {
      // TODO: Maybe we want to handle double clicking on objects differently
      const obj = e.target as EcogardenFabricObjects;

      if (obj) {
        // do not zoom into text boxes which require double click to enter
        if (obj.type === "textbox") {
          if (obj.selectable === false) {
            obj.selectable = true;
            canvas.setActiveObject(obj);
            canvas.renderAll();
            obj.on("deselected", () => {
              // handle restore selelectablity
              obj.selectable = false;
              // canvas.discardActiveObject()
            });
          }

          return;
        }

        if (obj.type === "line") {
          return;
        }

        if (obj.type === "polygon") {
          //	if the item is selectable then we are likely
          //	editing it or trying to select it.
          if (obj.selectable) {
            return;
          }
        }

        if (obj.type !== undefined) {
          return;
        }
      }
      //
      // zoom in on double click without a target
      // const currentZoom = canvas.getZoom();
      // const newZoom = currentZoom + currentZoom / 5;
      // console.log("zoomToPoint", newZoom);
      const newZoomLevel = _zoom + 1;
      const point = canvas.getPointer(e.e, true) as fabric.Point;
      const latitude = 45;

      if (zoomOutOfBounds(newZoomLevel)) {
        const fabricZoomLevel = OSMZoomLevelToFabricZoomLevel(latitude)(
          clampZoom(newZoomLevel)
        );
        canvasZoomToPoint(canvas)(store.dispatch)(fabricZoomLevel)(point);
        return;
      }

      // const scale = zoomLevelToFabricZoomLevel(45)(zoom.level)

      const fabricZoomLevel = OSMZoomLevelToFabricZoomLevel(latitude)(
        clampZoom(newZoomLevel)
      );
      canvasZoomToPoint(canvas)(dispatch)(fabricZoomLevel)(point);
      return;
    };

    const handleWheel = handleWheelZoom(canvas)(store);

    canvas.on({
      "mouse:dblclick": handleDoubleClick,
      "mouse:wheel": handleWheel,
    });

    return function cleanup(): void {
      canvas.off({
        "mouse:dblclick": handleDoubleClick,
        "mouse:wheel": handleWheel,
      });
    };
  }, [_zoom, store, canvas, dispatch]);

  // Need to redo how we do touch events on the canvas
  // useEffect(() => {
  // 	if (!canvas) {
  // 		return;
  // 	}

  // 	if (!manager) {
  // 		return;
  // 	}

  // 	// eslint-disable-next-line functional/prefer-readonly-type
  // 	const first: { rotation?: number; scale?: number } = {
  // 		rotation: undefined,
  // 		scale: undefined,
  // 	};

  // 	// Pinch and rotate touch
  // 	// Pinch/rotate the canvas
  // 	// Waiting on https://github.com/fabricjs/fabric.js/pull/4624 to enable canvas rotation
  // 	const handlePinchAndRotate: HammerListener = (e): void => {
  // 		canvas.selection = false;

  // 		if (!first.rotation || !first.scale) {
  // 			first.rotation = e.rotation;
  // 			first.scale = e.scale;
  // 		}

  // 		if (e.isFinal) {
  // 			if (first.rotation && first.scale) {
  // 				console.log(
  // 					"final delta",
  // 					e.rotation - first.rotation,
  // 					e.scale - first.scale
  // 				);
  // 			}

  // 			canvas.selection = true;
  // 			first.rotation = undefined;
  // 			first.scale = undefined;
  // 		}

  // 		// Debug
  // 		// const touch = document.getElementById("touch-gestures");

  // 		// if (touch) {
  // 		// 	touch.innerHTML = " pinchstart";
  // 		// }
  // 	};

  // 	manager.get("pinch").set({ enable: true });
  // 	manager.on("pinch", handlePinchAndRotate);

  // 	return function cleanup() {
  // 		manager.get("pinch").set({ enable: false });
  // 		manager.off("pinch", handlePinchAndRotate);
  // 	};
  // });

  if (!canvas) {
    return <></>;
  }

  return (
    <ToolButton
      label={`Zoom Out`}
      icon={ZoomOutIcon}
      type="button"
      size={24}
      tippyProps={{ placement: "left" }}
      onClick={(e) => {
        if (canvas) {
          handleZoomOut(canvas)(store)();
        }

        setTimeout(() => {
          (e.target as HTMLButtonElement).blur();
        }, 1000);
      }}
    />
  );
};

export default connect((state: RootState) => ({
  canvas: getCanvas(state.canvas) as ZoomEventCanvas,
  zoom: state.zoom,
}))(ZoomOut);
