import React from "react";
import { Grid } from "theme-ui";
import CopyTool from "./CopyTool";
import SaveImage from "../files/SaveImage";
// import ImageIcon from "../icons/image.svg";
import { Image } from "@styled-icons/feather/Image";
import Gesture from "./Gesture";
import Layers from "./Layers";
import Pan from "./PanTool";
import ToolButton from "./ToolButton";
import Tooltip from "./Tooltip";
import UndoRedo from "./UndoRedo";
import ZoomIn from "./ZoomIn";
import ZoomOut from "./ZoomOut";
import ObjectsContainer from "../objects/ObjectsContainer";
import { useAppSelector } from "../../lib/configureStore";

const Tools: React.FunctionComponent = () => {
  return (
    <Grid
      as="ul"
      p={1}
      gap={[2, 3]}
      bg="background"
      sx={{
        listStyle: "none",
        border: "panel",
        boxShadow: "panel",
      }}
    >
      <li>
        <ZoomIn />
      </li>
      <li>
        <ZoomOut />
      </li>
      <li>
        <Pan />
      </li>
    </Grid>
  );
};

const ImageButton: React.FunctionComponent = (properties) => {
  return (
    <ToolButton
      label="Save as Image"
      icon={Image}
      type="button"
      size={24}
      tippyProps={{ placement: "left" }}
      {...properties}
    />
  );
};

// eslint-disable-next-line max-lines-per-function
const ToolsContainer: React.FunctionComponent = () => {
  const objectsList = useAppSelector((state) => state.features["objects-list"]);
  return (
    <Grid
      p={[1, 2]}
      gap={[1, 2]}
      sx={{
        gridArea: "tools",
        pointerEvents: "none",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "1fr auto",
        justifyContent: "flex-end",
        alignSelf: "flex-start",
        zIndex: 2,
        position: "relative",
      }}
    >
      <Grid
        sx={{
          p: 1,
          alignItems: "flex-start",
          gridTemplateColumns: "1fr 1fr",
          height: "fit-content",
          padding: [1],
        }}
      >
        <SaveImage ImageButton={ImageButton} />
        <Layers title="Layers" />
        <UndoRedo />
      </Grid>
      <Tools />

      <Grid sx={{ justifyContent: "flex-end", gridColumn: "span 2" }}>
        <Tooltip />
        {objectsList && <ObjectsContainer />}
        <Gesture />
      </Grid>
    </Grid>
  );
};

export default ToolsContainer;
