import { IDBPDatabase } from "idb";
import { Store } from "redux";
import { EcogardenDB } from "../design";
import { RootState } from "../lib/configureStore";
import profiling, { Profiling } from "../lib/profiling";

type Filters = ReadonlyArray<(key: string) => void>;

/**
 * Save state to indexedDB
 */
export const saveState =
	(db: IDBPDatabase<EcogardenDB>) =>
	(store: Store<RootState>) =>
	(filters: Filters) =>
	async (): Promise<(string | void)[]> => {
		// eslint-disable-next-line compat/compat
		profiling.start(Profiling.Debug)("saveState");
		const state = Object.fromEntries(
			Object.entries(store.getState()).filter(([key]) =>
				["objects", "settings", "viewport", "features", "layers"].includes(key)
			)
		) as Partial<RootState>;
		return Promise.all([db.put("db", state, "maindb")]).then(
			async (results): Promise<string[]> => {
				profiling.stop(Profiling.Debug)("saveState");
				return results;
			}
		);
	};

/**
 * Load state from indexedDB
 */
export const loadStateFromDB = async (
	db: Readonly<IDBPDatabase<EcogardenDB>>
): Promise<Readonly<Partial<RootState>> | undefined> => {
	profiling.start(Profiling.Debug)("loadState");
	const state = await db.get("db", "maindb");

	profiling.stop(Profiling.Debug)("loadState");
	return state;
};
