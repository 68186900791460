import React from "react";
import { connect, useStore } from "react-redux";
import type { Store } from "redux";
import { getCanvas } from "../../design/canvas";
import { canvasZoom } from "../../lib/canvas";
import type { RootState } from "../../lib/configureStore";
import {
  add,
  calculateNewZoomLevel,
  OSMZoomLevelToFabricZoomLevel,
} from "../../lib/fabric/zoom";
import { ZoomIn as ZoomInIcon } from "@styled-icons/feather/ZoomIn";
import ToolButton from "./ToolButton";

/**
 * Handle zoom in clicks
 */
const handleZoomIn = (canvas: fabric.Canvas) => (store: Store) => (): void => {
  // const point = canvas.getVpCenter();
  const state = store.getState();

  // TODO: replace with value
  const lat = 45;
  const newZoomLevel = calculateNewZoomLevel(add)(state.zoom);
  const fabricZoomLevel = OSMZoomLevelToFabricZoomLevel(lat)(newZoomLevel);

  canvasZoom(canvas)(store.dispatch)(fabricZoomLevel);
};

interface ZoomInState extends Pick<RootState, "zoom"> {
  readonly canvas?: fabric.Canvas;
}

/**
 * ZoomIn button
 */
// eslint-disable-next-line max-lines-per-function
const ZoomIn: React.FunctionComponent<ZoomInState> = ({ canvas }) => {
  const store = useStore();

  if (!canvas) {
    return <></>;
  }

  return (
    <ToolButton
      label={`Zoom In`}
      icon={ZoomInIcon}
      type="button"
      size={24}
      tippyProps={{ placement: "left" }}
      onClick={(e) => {
        if (canvas) {
          handleZoomIn(canvas)(store)();
        }
        setTimeout(() => {
          (e.target as HTMLButtonElement).blur();
        }, 1000);
      }}
    />
  );
};

export default connect((state: RootState) => ({
  zoom: state.zoom,
  canvas: getCanvas(state.canvas),
}))(ZoomIn);
