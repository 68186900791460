/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { Check } from "@styled-icons/feather/Check";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { Box, Button, Flex, Grid, Input, Label, Radio } from "theme-ui";
import { v4 as uuidv4 } from "uuid";
import { addObject } from "../../actions/objects";
import { getCanvas } from "../../design/canvas";
import slideUp from "../../lib/animations/slideup";
import { useAppSelector } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { EcogardenFabricPath } from "../../lib/fabric/objects";
import { toEcogardenObject } from "../../lib/objects";
import { attachPathEvents } from "../../lib/objects/freepath";
import { Shape } from "../../shapes";
import IconBlock from "../buttons/IconBlock";
import { fabric } from "fabric";
import { PathSizes, sizeToNumber } from "../../lib/path";
import LineIcon from "../../../svg/objects/line.svg";
import StrokeSizes from "../shapes/StrokeSizes";

const FreedrawTool: React.FunctionComponent<{
  readonly type: Shape;
  readonly onCancel: () => void;
  readonly onFinish: () => void;
}> = ({ onCancel, onFinish, type }) => {
  const [color, setColor] = useState<string | undefined>(undefined);
  const [size, setSize] = useState<PathSizes>("medium");
  const [showSizes, setShowSizes] = useState<boolean>(false);
  const { canvas, layers } = useAppSelector((state) => {
    return {
      canvas: getCanvas(state.canvas) as EcogardenCanvas,
      layers: state.layers,
    };
  });

  const dispatch = useDispatch();

  useHotkeys("esc", () => {
    // If we are in the size selection, escape should cancel that instead
    if (showSizes) {
      setShowSizes(false);
    } else {
      onCancel();
    }
  });

  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.isDrawingMode = true;
    // brushes take canvas as an argument
    // // @ts-ignore
    // canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    // types do not have decimate but it is valid
    // @ts-ignore
    (canvas.freeDrawingBrush as fabric.PencilBrush).decimate = 5;

    return function cleanup() {
      canvas.isDrawingMode = false;
    };
  }, [canvas]);

  useEffect(() => {
    if (!canvas) {
      return;
    }
    canvas.freeDrawingBrush.color = color ?? "black";
    canvas.freeDrawingBrush.width = size ? sizeToNumber(size) : 2;
  }, [canvas, color, size]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handlePathCreated: (e: {
      readonly path: EcogardenFabricPath;
    }) => void = (e) => {
      const path = e.path as EcogardenFabricPath;

      if (!path) {
        return;
      }

      path.set({ id: uuidv4(), subtype: type, perPixelTargetFind: true });

      attachPathEvents(path);
      // applyLayerFilters(canvas)(layers);

      dispatch(addObject(toEcogardenObject(path)));
    };

    // path:created passes only a path but the types does not reflect this
    canvas.on("path:created", handlePathCreated as () => void);

    return function cleanup() {
      canvas.off("path:created", handlePathCreated as () => void);
    };
  }, [canvas, layers, dispatch, type]);

  return (
    <Box
      sx={{
        pointerEvents: "all",
        width: ["100vw", "22em"],
        maxWidth: ["calc(var(--vh, 1vh) * 100)", "75vw"],
      }}
    >
      <Grid>
        {showSizes && (
          <StrokeSizes
            size={size}
            setSize={setSize}
            onSelected={() => {
              setShowSizes(false);
            }}
          />
        )}
      </Grid>
      <Grid
        sx={{
          backgroundColor: "accent-bg",
          px: [3, 2],
          py: 2,

          zIndex: 2,
          gridTemplateColumns: "44px 1fr auto",
          alignItems: "center",
          gridGap: 4,
          borderRadius: "panelBottom",
          justifyItems: "center",
          animation: `${slideUp} 96ms ease-in-out`,
          fontSize: [0, 1, 2],
        }}
      >
        <Label>
          <Input
            sx={{ padding: 0, height: [33, 44], width: 44 }}
            type="color"
            name="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </Label>
        <Box>
          <Button
            variant="default"
            onClick={(e) => {
              setShowSizes(true);
              (e.target as HTMLButtonElement).blur();
            }}
          >
            <LineIcon
              width="20"
              height="20"
              strokeWidth={sizeToNumber(size) / 2}
            />{" "}
            {capitalize(size)}
          </Button>
        </Box>
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="default"
            onClick={() => {
              onFinish();
            }}
          >
            <IconBlock icon={Check} /> Finish
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};

export default FreedrawTool;
