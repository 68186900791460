import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StyledModal, { ModalProps } from "styled-react-modal";
import { Box } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import { RootState } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { applyLayerFilters } from "../../lib/fabric/layers";
import LayersIcon from "../icons/layers.svg";
import MenuHeader from "../MenuHeader";
import LayersList from "./LayersList";
import { default as ToolButton } from "./ToolButton";

const Modal: React.FunctionComponent<ModalProps> = (properties) => (
  <StyledModal {...properties}></StyledModal>
);

const Layers: React.FunctionComponent<{
  readonly onCloseRequest?: () => void;
  readonly title?: string;
  readonly isOpen?: boolean;
  // eslint-disable-next-line max-lines-per-function
}> = ({ onCloseRequest, title, isOpen }) => {
  const [showModal, setShowModal] = useState(isOpen ? true : false);
  const { layers, canvas } = useSelector((state: RootState) => ({
    layers: state.layers,
    canvas: getCanvas(state.canvas) as EcogardenCanvas,
  }));

  const onClose = (): void => {
    setShowModal(false);
    onCloseRequest && onCloseRequest();
  };

  useEffect(() => {
    if (!canvas) {
      return;
    }

    // Handle updating layers state in the fabric canvas

    // iterate over all objects and apply the layers state to each
    applyLayerFilters(canvas)(layers);
  }, [canvas, layers]);

  return (
    <>
      <ToolButton
        icon={LayersIcon}
        label={title ?? "Labels"}
        onClick={(e) => {
          setShowModal(true);

          setTimeout(() => {
            (e.target as HTMLButtonElement).blur();
          }, 1000);
        }}
      />
      <Modal
        onBackgroundClick={onClose}
        onEscapeKeydown={onClose}
        isOpen={showModal}
      >
        <Box
          bg="background"
          sx={{
            borderRadius: "primary",
            border: "panel",
            boxShadow: "panel",
            minWidth: "18em",
            width: "25vw",
            maxWidth: ["100vw", "35em"],
            transition: "all ease-in-out 96ms",
            maxHeight: "calc(var(--vh, 1vh) * 95)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {title ? (
            <MenuHeader onCloseRequest={onClose}>{title}</MenuHeader>
          ) : (
            ""
          )}
          <LayersList />
        </Box>
      </Modal>
    </>
  );
};

export default Layers;
