import React from "react";
import IconButton from "../buttons/IconButton";
import { Trash } from "@styled-icons/feather/Trash";

const TrashButton: React.FunctionComponent<{
  readonly onTrashClick: React.EventHandler<React.MouseEvent>;
}> = ({ onTrashClick }) => (
  <IconButton onClick={onTrashClick} icon={Trash} label="Trash" />
);

export default TrashButton;
