/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable @typescript-eslint/prefer-readonly-parameter-types */
import type { fabric } from "fabric";

/**
 * This is designed to be a stopgap at getting to non-globals.
 * We eventually want to move away from this.
 * In production we usually have 1 canvas but may eventually have many.
 * This is used a lot to set for tests which never get properly cleaned up.
 */

export const newCanvas =
  (key: string) =>
  (canvas: fabric.Canvas): Map<string, fabric.Canvas> =>
    new Map([[key, canvas]]);

let CANVASES: Map<string, fabric.Canvas> | undefined = undefined;

/** Get fabric.Canvas from the collection of canvases */
export const getCanvas = (
  id: string | undefined | null
): fabric.Canvas | undefined => {
  if (!id) {
    return undefined;
  }

  if (!CANVASES) {
    return undefined;
  }

  return CANVASES.get(id);
};

/** Set the fabric.Canvas for the id */
export const setCanvas = (
  id: string,
  canvas: fabric.Canvas
): Map<string, fabric.Canvas> => {
  if (!CANVASES) {
    CANVASES = newCanvas(id)(canvas);

    return CANVASES;
  }

  return CANVASES.set(id, canvas);
};

export const clearCanvases = (): void => {
  CANVASES?.clear();
};
