import React, { useEffect, useState } from "react";
import ToolButton from "./ToolButton";

import MaximizeIcon from "../icons/maximize.svg";
import MinimizeIcon from "../icons/minimize.svg";
import { Box } from "theme-ui";

const FullScreen: React.FunctionComponent = () => {
  const [state, setState] = useState<boolean>(false);

  const handleFullScreenToggle = (): void => {
    setState((state) => !state);
  };

  useEffect(() => {
    if (state) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }, [state]);

  if (!document.fullscreenEnabled) {
    return <></>;
  }

  return (
    <Box sx={{ gridArea: "full", padding: 1 }}>
      <ToolButton
        label={`Fullscreen`}
        icon={state ? MinimizeIcon : MaximizeIcon}
        type="button"
        size={24}
        tippyProps={{ placement: "top" }}
        onClick={(e) => {
          handleFullScreenToggle();

          setTimeout(() => {
            (e.target as HTMLButtonElement).blur();
          }, 1000);
        }}
      />
    </Box>
  );
};

export default FullScreen;
